app.components.storefinder = {
    container: '',
    locations: [],
    map: {},
    searchForm: false,

    onReady: function () {
        return $('.c-storefinder .googlemap-canvas').length > 0;
    },

    addListener: function () {
        Promise.all([
            import(/* webpackChunkName: "twig" */ 'twig/twig.min.js'),
            import(/* webpackChunkName: "markerclusterer" */ '@googlemaps/markerclusterer')
        ]).then(([twigModule, markerClustererModule]) => {
            window.Twig = twigModule.default;
            window.MarkerClusterer = markerClustererModule.MarkerClusterer;
            this.setup($('.c-storefinder').attr('id'));
        });
    },

    setup: function (container) {
        if (window.google && google.maps) {
            this.initMap(container);
        } else {
            const callBackName = `${this.container}_callback`;
            window[callBackName] = () => this.setup(container);

            const script = document.createElement('script');
            script.src = `https://maps.google.com/maps/api/js?key=${window.googlemap.key}&libraries=places,marker&callback=${callBackName}`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    },

    initMap: function (container) {
        this.container = `#${container}`;
        this.locations = googlemap.locations[container];
        this.markers = [];

        this.map = new google.maps.Map($('.googlemap-canvas', this.container)[0], {
            mapId: '359aa1ba7b6d240d',
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            },
            panControl: true,
            panControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.LARGE,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            }
        });

        addSwissOverlay(this.map);

        this.infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(-14, -35)
        });
        const bounds = new google.maps.LatLngBounds();
        const template = Twig.twig({data: $('#map-template').html()});
        this.oms = {};

        this.searchFormMap = $('.c-storefinder__search__map', this.container);
        this.searchInputMap = $('input', this.searchFormMap);
        this.searchFormText = $('.c-storefinder__search__text', this.container);
        this.searchInputText = $('input', this.searchFormText);


        google.maps.event.addListenerOnce(this.map, 'idle', () => {

            this.locations.forEach((location, i) => {
                location.point = new google.maps.LatLng(location.lat, location.lng);

                const markerContent = document.createElement('div');
                markerContent.className = 'ppag-custom-marker';

                const markerImage = document.createElement('img');
                markerImage.src = location.icon;
                markerImage.alt = location.title;
                markerContent.appendChild(markerImage);

                location.marker = new google.maps.marker.AdvancedMarkerElement({
                    position: location.point,
                    map: this.map,
                    title: location.title,
                    content: markerContent,
                    zIndex: i
                });

                bounds.extend(location.marker.position);

                google.maps.event.addListener(location.marker, 'click', () => {
                    this.infowindow.setContent(template.render(location.company));
                    this.infowindow.open(this.map, location.marker);
                });

                this.markers[i] = location.marker;
            });

            this.map.fitBounds(bounds);

            const listener = google.maps.event.addListener(this.map, "idle", () => {
                this.map.setZoom(8);
                this.map_center = this.map.getCenter();
                google.maps.event.removeListener(listener);
            });

            new MarkerClusterer({
                map: this.map,
                markers: this.markers,
                renderer: {
                    render: ({count, position}) => {
                        const color = "#b4d53c";
                        const svgContent = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="45" height="45">
                        <circle cx="120" cy="120" opacity=".6" r="70" />
                        <circle cx="120" cy="120" opacity=".3" r="90" />
                        <circle cx="120" cy="120" opacity=".2" r="110" />
                    </svg>`;

                        const markerContent = document.createElement('div');
                        markerContent.innerHTML = svgContent;

                        const label = document.createElement('span');
                        label.textContent = String(count);
                        label.style.color = "rgba(0,0,0,0.9)";
                        label.style.fontSize = "12px";
                        label.style.fontWeight = "bold";
                        label.style.position = "absolute";
                        label.style.top = "50%";
                        label.style.left = "50%";
                        label.style.transform = "translate(-50%, -50%)";
                        markerContent.appendChild(label);

                        return new google.maps.marker.AdvancedMarkerElement({
                            position,
                            map: this.map,
                            content: markerContent,
                            zIndex: count
                        });

                    }
                }
            });

            this.initUserGeolocation();
            this.initUserSearch();
            this.renderResults();
        });

    },

    initUserSearch: function () {
        const searchBox = new google.maps.places.Autocomplete(
            this.searchInputMap[0],
            {componentRestrictions: {country: "ch"}}
        );

        google.maps.event.addListener(searchBox, 'place_changed', () => this.searchFormMap.trigger('submit'));

        this.searchFormMap.on('submit', e => {
            e.preventDefault();
            this.searchFormMap.addClass("load");

            const search = this.searchInputMap.val();
            this.resetResults();
            this.searchInputMap.val(search);

            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': this.searchInputMap.val()}, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    const searchPosition = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                    this.locations.forEach(location => {
                        location.dist = google.maps.geometry.spherical.computeDistanceBetween(searchPosition, location.point);
                    });

                    this.locations.sort((a, b) => a.dist - b.dist);

                    this.map.setCenter(this.locations[0].point);
                    this.map.setZoom(13);
                    google.maps.event.trigger(this.locations[0].marker, "click");

                    this.searchFormMap.removeClass("load");
                    this.searchInputMap.removeClass("error");
                    this.renderResults();
                } else {
                    this.searchInputMap.addClass("error");
                    this.searchFormMap.removeClass("load");
                }
            });
        });

        this.searchFormText.on('submit', e => {
            e.preventDefault();
            const search = this.searchInputText.val();
            this.resetResults();
            this.searchInputText.val(search);
            this.renderResults(search);
        });
    },

    initUserGeolocation: function () {
        this.searchInputMap.one('click', () => {
            if (navigator.geolocation) {
                this.searchFormMap.addClass("load");
                navigator.geolocation.getCurrentPosition(
                    position => this.getAddressFromCoords(new google.maps.LatLng(position.coords.latitude, position.coords.longitude)),
                    () => this.searchFormMap.removeClass("load")
                );
            }
        });
    },

    getAddressFromCoords: function (coords) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'latLng': coords}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                const address = results[0]["formatted_address"];
                this.searchInputMap.val(address);
                this.searchFormMap.removeClass("load").trigger('submit');
            }
        });
    },

    renderResults: function (search = '') {
        const resultsDiv = $('#storefinder .row');
        resultsDiv.empty();

        search = search.toLowerCase();

        const template = window.Twig.twig({data: $('#store-template').html()});

        this.locations.forEach((entry, i) => {
            entry.company.id = entry.id;
            entry.company.count = i;
            if (entry.dist !== false) {
                entry.company.dist = `${number_format(entry.dist / 1000, 2)} km`;
            }

            entry.company.search = `${entry.company.bsla_company_name.toLowerCase()} ${entry.company.bsla_company_description?.toLowerCase() || ''} ${entry.company.bsla_company_offer?.toLowerCase() || ''}`;

            if (!search || entry.company.search.includes(search)) {
                resultsDiv.append(template.render(entry.company));
            }
        });

        if (this.searchInputMap.val()) {
            $('.store', resultsDiv).eq(0).addClass('current');
            $('.store__dist', resultsDiv).show();
        } else {
            $('.store__dist', resultsDiv).hide();
        }

        $('.section--storefinder .row').removeClass('masonryInit');
        app.components.masonry.addListener();

        $('.modal').on('shown.bs.modal', () => app.components.slider.addListener());
    },

    resetResults: function () {
        this.searchInputText.val("");
        this.searchInputMap.val("");
        $('.store__dist', $('#storefinder .row')).hide();

        this.locations.forEach(location => location.dist = false);

        this.locations = this.shuffleArray(this.locations);
        this.searchFormMap.removeClass("load");
        this.searchInputMap.removeClass("error");
        this.renderResults();
    },

    shuffleArray: function (array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
};
