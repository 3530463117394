app.components.sponsors = {
    global: false,
    onReady: function () {

    },
    addListener: function () {

    },
    resize: function () {

    },
    scroll: function () {

    }
};